// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-auftragsarbeiten-jsx": () => import("./../../../src/pages/auftragsarbeiten.jsx" /* webpackChunkName: "component---src-pages-auftragsarbeiten-jsx" */),
  "component---src-pages-datenschutz-jsx": () => import("./../../../src/pages/datenschutz.jsx" /* webpackChunkName: "component---src-pages-datenschutz-jsx" */),
  "component---src-pages-impressum-jsx": () => import("./../../../src/pages/impressum.jsx" /* webpackChunkName: "component---src-pages-impressum-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-kontakt-jsx": () => import("./../../../src/pages/kontakt.jsx" /* webpackChunkName: "component---src-pages-kontakt-jsx" */),
  "component---src-pages-styleguide-jsx": () => import("./../../../src/pages/styleguide.jsx" /* webpackChunkName: "component---src-pages-styleguide-jsx" */),
  "component---src-pages-toepfertechniken-jsx": () => import("./../../../src/pages/toepfertechniken.jsx" /* webpackChunkName: "component---src-pages-toepfertechniken-jsx" */),
  "component---src-pages-tonarbeiten-jsx": () => import("./../../../src/pages/tonarbeiten.jsx" /* webpackChunkName: "component---src-pages-tonarbeiten-jsx" */),
  "component---src-pages-ueber-mich-jsx": () => import("./../../../src/pages/ueber-mich.jsx" /* webpackChunkName: "component---src-pages-ueber-mich-jsx" */),
  "component---src-pages-veranstaltungen-jsx": () => import("./../../../src/pages/veranstaltungen.jsx" /* webpackChunkName: "component---src-pages-veranstaltungen-jsx" */)
}

